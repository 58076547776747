<!--
 * @Descripttion: 
 * @version: 
 * @Author: hutian
 * @Date: 2021-03-17 17:22:40
 * @LastEditors: hutian
 * @LastEditTime: 2021-03-17 17:52:14
-->
<template>
  <div class="integral_detail">
    <a-modal v-model="visible" :title="title" :width="1000" :footer="null" @cancel="onCancel" :maskClosable="false">
      <a-table :data-source="tableData" :columns="columns" bordered :pagination="page">
        <span slot="dealIntegral" slot-scope="text, row">
          <span v-if="row.amountType == 1" style="color: rgb(82, 196, 26)">+{{row.dealIntegral}}</span>
          <span v-if="row.amountType == 2" style="color: rgb(245, 34, 45)">-{{row.dealIntegral}}</span>
        </span>
      </a-table>
    </a-modal>
  </div>
</template>

<script>
  import { IntegralDetailColumns } from './colums.js'
  const pageSource = {
    current: 1,
    pageSize: 10,
    total: 0
  }
  export default {
    data() {
      return {
        tableData: [],
        columns: IntegralDetailColumns,
        visible: false,
        title: '',
        handle: '',
        page: {
                current: 1,
                pageSize: 10,
                total: 0,
                showSizeChanger: true,
                showTotal: () => `共${this.page.total}条`,
            },
      }
    },
    methods: {
      // 打开页面时触发
      isShow(row, handle) {
        this.handle = handle
        this.visible = true
        this.title = row.dealerName
        this.getList(row.dealerId)
      },
      getList(dealerId) {
        this.axios.get('/api/dealer/dealer/dealerIntegralRecord/list', {params: {
          pageNumber: this.page.current, 
          pageSize: this.page.pageSize,
          dealerId
        }}).then(res => {
          this.tableData = res.body.records
          this.page.total = res.body.total
        })
      },
      // 关闭弹框
      onCancel() {
        this.visible = false
      },
    }
  }
</script>

<style lang="scss">
  
</style>
